<template>
  <EdgeStack
    :id="esId"
    class="relative"
    header-class-name="mb-4 mt-8 px-4"
    :confirmQuestion="$t('components.edgeStack.confirmQuestion')"
  >
    <loading :active.sync="reqBusy" :is-full-page="false" />

    <template #header>
      <div class="flex justify-between">
        <div class="items-center panel-title header-title">
          {{ getTitleText }}
        </div>
      </div>
    </template>

    <template #footer>
      <t-button type="submit" @click="submit()" class="ml-3">{{
        getActionButtonText
      }}</t-button>
    </template>

    <div class="px-6 pt-8">
      <PromoCard
        ref="promoCard"
        :promoDiscountText="getPromoDiscountText"
        :promoTitle="getPromoTitle"
        :promoCode="getPromoCode"
        :promoDescription="getPromoDescription"
        :promoExpiredDate="getPromoExpiredDate"
      />

      <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
        <form
          @submit.prevent="handleSubmit(submitForm)"
          enctype="multipart/form-data"
        >
          <div class=" mt-8">
            <AppInput
              type="richselect"
              :name="
                $t(
                  'components.promoManagement.addEdit.steps.promo.title.promoType'
                )
              "
              :label="
                $t(
                  'components.promoManagement.addEdit.steps.promo.title.promoType'
                )
              "
              :options="getPromoCategoryOptions"
              hide-search-box
              v-model="form.promotion_category"
              rules="required"
            />
          </div>
          <div class="grid grid-cols-2 gap-4 mt-2">
            <text-input
              v-model="form.title"
              rules="required"
              :label="
                $t(
                  'components.promoManagement.addEdit.steps.promo.title.promoName'
                )
              "
              placeholder="e.g. Bijoy"
              :errorMessage="customError.title"
              errorKey="title"
              @clear-error="
                ($event) => {
                  customError[$event] = ''
                }
              "
            />
            <text-input
              :value="form.code.toUpperCase()"
              rules="required"
              :label="
                $t(
                  'components.promoManagement.addEdit.steps.promo.title.promoCode'
                )
              "
              placeholder="e.g. BIJOY16"
              :errorMessage="customError.code"
              errorKey="code"
              @input="
                ($event) => {
                  form.code = $event.toUpperCase()
                }
              "
              @clear-error="
                ($event) => {
                  customError[$event] = ''
                }
              "
            />
          </div>
          <div class="grid grid-cols-2 gap-4 mt-2">
            <text-input
              v-model="form.start_date"
              rules="required"
              :label="
                $t(
                  'components.promoManagement.addEdit.steps.promo.title.promoStartDate'
                )
              "
              type="date"
              :min="minStartDate"
            />
            <text-input
              v-model="form.end_date"
              rules="required"
              :label="
                $t(
                  'components.promoManagement.addEdit.steps.promo.title.promoExpireDate'
                )
              "
              type="date"
              :min="minEndDate"
            />
          </div>
          <div class="w-full mt-2">
            <text-input
              :value="getPromoValidityDuration"
              :label="
                $t(
                  'components.promoManagement.addEdit.steps.promo.title.usageValidity'
                )
              "
              placeholder="days"
              disabled
            />
          </div>
          <div class="mt-2">
            <ValidationProvider
              :name="
                $t(
                  'components.promoManagement.addEdit.steps.promo.title.fleetCurrency'
                )
              "
              rules="required"
              v-slot="{ errors }"
            >
              <input-label
                :text="
                  $t(
                    'components.promoManagement.addEdit.steps.promo.title.fleetCurrency'
                  )
                "
                :error="errors[0]"
              />
              <TRichSelect
                v-model="fleetCurrencyProxy"
                :options="getFleetCurrencyOptions"
                :variant="{ danger: !!errors[0] }"
                :placeholder="
                  $t(
                    'components.promoManagement.addEdit.steps.promo.placeholder.fleetCurrency'
                  )
                "
              />
              <input-error-item :message="errors[0]" v-if="errors[0]" />
            </ValidationProvider>
            <div
              class="mt-1 text-xs text-gray-600"
              v-html="
                $t(
                  'components.promoManagement.addEdit.steps.promo.infoDescription.fleetCurrency.fleetsInfo',
                  {
                    count: fleetList.length || 0,
                    currencyCount: getFleetCurrencyOptions.length || 0,
                    currencyText: $t(
                      getFleetCurrencyOptions.length > 1
                        ? 'components.promoManagement.addEdit.steps.promo.infoDescription.fleetCurrency.currencies'
                        : 'components.promoManagement.addEdit.steps.promo.infoDescription.fleetCurrency.currency'
                    ),
                  }
                )
              "
            />
          </div>

          <div class="mt-4">
            <ValidationProvider
              :name="
                $t(
                  'components.promoManagement.addEdit.steps.promo.title.assignedFleet'
                )
              "
              rules="required"
              v-slot="{ errors }"
            >
              <input-label
                :error="errors[0]"
                :text="
                  $t(
                    'components.promoManagement.addEdit.steps.promo.title.assignedFleet'
                  )
                "
              />
              <TRichSelect
                v-model="form.assigned_fleets"
                :options="getFleetOptions"
                :close-on-select="false"
                :variant="{ danger: !!errors[0] }"
                :placeholder="
                  fleetCurrencyProxy
                    ? $t(
                        'components.promoManagement.addEdit.steps.promo.placeholder.assignedFleet.selectFleets'
                      )
                    : $t(
                        'components.promoManagement.addEdit.steps.promo.placeholder.assignedFleet.selectFleetCurrencyFirst'
                      )
                "
                multiple
              />
              <input-error-item :message="errors[0]" />
            </ValidationProvider>
          </div>

          <div class="mt-4">
            <ValidationProvider
              :name="
                $t(
                  'components.promoManagement.addEdit.steps.promo.title.targetRiderProfile'
                )
              "
              rules="required"
              v-slot="{ errors }"
            >
              <input-label
                :error="errors[0]"
                :text="
                  $t(
                    'components.promoManagement.addEdit.steps.promo.title.targetRiderProfile'
                  )
                "
              />
              <TRichSelect
                v-model="form.eligible_user_types"
                :options="targetRiderProfileOptions"
                :close-on-select="false"
                :variant="{ danger: !!errors[0] }"
                :placeholder="
                  $t(
                    'components.promoManagement.addEdit.steps.promo.placeholder.targetRiderProfile'
                  )
                "
                multiple
                hideSearchBox
              />
              <input-error-item :message="errors[0]" />
            </ValidationProvider>
          </div>

          <div class="mt-4">
            <ValidationProvider
              :name="
                $t(
                  'components.promoManagement.addEdit.steps.promo.title.targetRiderGroup'
                )
              "
              rules="required"
              v-slot="{ errors }"
            >
              <input-label
                :error="errors[0]"
                :text="
                  $t(
                    'components.promoManagement.addEdit.steps.promo.title.targetRiderGroup'
                  )
                "
              />
              <TRichSelect
                v-model="form.eligible_point_categories"
                :options="targetGroupOptions"
                :close-on-select="false"
                :variant="{ danger: !!errors[0] }"
                :placeholder="
                  $t(
                    'components.promoManagement.addEdit.steps.promo.placeholder.targetRiderGroup'
                  )
                "
                multiple
                hideSearchBox
              />
              <input-error-item :message="errors[0]" />
            </ValidationProvider>
          </div>

          <div
            class="grid grid-cols-2 gap-4 mt-2"
            v-if="getPromoCategory != 'FR'"
          >
            <template v-if="getPromoCategory == 'RD'">
              <AppInput
                type="number"
                rules="required"
                :name="
                  $t(
                    'components.promoManagement.addEdit.steps.promo.title.discountPercentage'
                  )
                "
                :label="
                  $t(
                    'components.promoManagement.addEdit.steps.promo.title.discountPercentage'
                  )
                "
                placeholder="e.g. 60"
                v-model="form.percentage"
                step="0.01"
                :haveUnitText="true"
                unitText="%"
              />
            </template>

            <template v-else-if="getPromoCategory == 'FD'">
              <AppInput
                type="number"
                rules="required"
                :name="
                  $t(
                    'components.promoManagement.addEdit.steps.promo.title.flatDiscountAmount'
                  )
                "
                :label="
                  $t(
                    'components.promoManagement.addEdit.steps.promo.title.flatDiscountAmount'
                  )
                "
                placeholder="e.g. $20"
                v-model="form.flat_discount_amount"
                :haveSuggestion="true"
                :suggestionText="
                  $t(
                    'components.promoManagement.addEdit.steps.promo.infoDescription.flatDiscountAmount'
                  )
                "
                step="0.01"
              />
            </template>

            <AppInput
              type="number"
              rules="required"
              :name="
                $t(
                  'components.promoManagement.addEdit.steps.promo.title.minimumTripTime'
                )
              "
              :label="
                $t(
                  'components.promoManagement.addEdit.steps.promo.title.minimumTripTime'
                )
              "
              placeholder="e.g. 20"
              v-model="form.min_travel_time_in_sec"
              :haveSuggestion="true"
              :suggestionText="
                $t(
                  'components.promoManagement.addEdit.steps.promo.infoDescription.minimumTripTime'
                )
              "
              step="1"
              :haveUnitText="true"
              unitText="Min"
            />
          </div>

          <div class="grid grid-cols-2 gap-4 mt-2">
            <AppInput
              type="number"
              rules="required"
              :name="
                $t(
                  'components.promoManagement.addEdit.steps.promo.title.maxNumberOfTrips'
                )
              "
              :label="
                $t(
                  'components.promoManagement.addEdit.steps.promo.title.maxNumberOfTrips'
                )
              "
              placeholder="e.g. 10"
              v-model="form.num_of_trips"
              :haveSuggestion="true"
              :suggestionText="
                $t(
                  'components.promoManagement.addEdit.steps.promo.infoDescription.maxNumberOfTrips'
                )
              "
              step="1"
              :haveUnitText="true"
              unitText="Trips"
            />

            <AppInput
              type="number"
              rules="required"
              :name="
                $t(
                  'components.promoManagement.addEdit.steps.promo.title.dailyUsed'
                )
              "
              :label="
                $t(
                  'components.promoManagement.addEdit.steps.promo.title.dailyUsed'
                )
              "
              placeholder="e.g. 10"
              v-model="form.daily_used"
              :haveSuggestion="true"
              :suggestionText="
                $t(
                  'components.promoManagement.addEdit.steps.promo.infoDescription.dailyUsed'
                )
              "
              step="1"
            />
          </div>
          <div class="grid grid-cols-2 gap-4 mt-2">
            <AppInput
              type="number"
              rules="required"
              :name="
                $t(
                  'components.promoManagement.addEdit.steps.promo.title.userLimit'
                )
              "
              :label="
                $t(
                  'components.promoManagement.addEdit.steps.promo.title.userLimit'
                )
              "
              placeholder="e.g. 10"
              v-model="form.user_limit"
              :haveSuggestion="false"
              step="1"
              :haveUnitText="true"
              unitText="Riders"
            />

            <AppInput
              type="richselect"
              :name="
                $t(
                  'components.promoManagement.addEdit.steps.promo.title.visibility'
                )
              "
              :label="
                $t(
                  'components.promoManagement.addEdit.steps.promo.title.visibility'
                )
              "
              :options="[
                { text: 'Public', value: 0 },
                { text: 'Private', value: 1 },
              ]"
              hide-search-box
              v-model="form.visibility"
            />
          </div>

          <div class="w-full" v-if="getPromoCategory == 'RD'">
            <AppInput
              type="number"
              rules="required"
              :name="
                $t(
                  'components.promoManagement.addEdit.steps.promo.title.maxDiscountedTripCost'
                )
              "
              :label="
                $t(
                  'components.promoManagement.addEdit.steps.promo.title.maxDiscountedTripCost'
                )
              "
              placeholder="e.g. $5"
              v-model="form.max_amount"
              :haveSuggestion="true"
              :suggestionText="
                $t(
                  'components.promoManagement.addEdit.steps.promo.infoDescription.maxDiscountedTripCost'
                )
              "
              step="0.01"
            />
          </div>
          <div class="mt-2">
            <AppInput
              type="richselect"
              :name="
                $t(
                  'components.promoManagement.addEdit.steps.promo.title.status'
                )
              "
              :label="
                $t(
                  'components.promoManagement.addEdit.steps.promo.title.status'
                )
              "
              :options="statusOptions"
              hide-search-box
              v-model="form.is_active"
              rules="required"
            />
          </div>

          <div class="mt-5">
            <ValidationProvider
              :name="
                $t(
                  'components.promoManagement.addEdit.steps.promo.title.promoDescription'
                )
              "
              v-slot="{ errors }"
            >
              <input-label
                :error="errors[0]"
                :text="
                  $t(
                    'components.promoManagement.addEdit.steps.promo.title.promoDescription'
                  )
                "
              />
              <t-textarea rules="" v-model="form.description" />
              <input-error-item :message="errors[0]" />
            </ValidationProvider>
          </div>

          <button type="submit" ref="submitButton" class="hidden">Save</button>
        </form>
      </ValidationObserver>

      <!-- prop: {{ fleetCurrency }} proxy: {{ fleetCurrencyProxy }} form: {{ form.assigned_fleets }} -->
    </div>
  </EdgeStack>
</template>

<script>
import { PromoConfig } from '@/config/PromoConfig'
import { EventBus, getPromoCategoryName } from '@/utils'
import { deepCompareObjects } from '@/utils'
import { edgeStackEmitter } from '@/plugins/edgeStack'
import PromoCard from '@/components/cards/PromoCard.vue'
import TextInput from '@/components/form/TextInput'
import InputLabel from '@/components/form/InputLabel'
import EdgeStack from '@/components/modals/EdgeStack.vue'
import dayjs from 'dayjs'
import { useEndpoints } from '@/composables'
export default {
  name: 'PromotionAddEdit',
  props: {
    esId: {
      type: [String, Number],
      required: false,
      default: 'promotionAddEdit',
    },
    primaryKey: {
      type: [String, Number],
      required: false,
      default: 0,
    },
    formData: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: false,
    },
    currencySymbol: {
      type: [String, Number],
      required: false,
      default: '$',
    },
    fleetCurrency: {
      type: [String, Number],
      required: false,
      default: '',
    },
  },
  components: {
    EdgeStack,
    PromoCard,
    TextInput,
    InputLabel,
  },
  data() {
    return {
      reqBusy: false,
      promoCurrencySymbol: '--',
      form: {
        is_active: true,
        title: '',
        code: '',
        start_date: '',
        end_data: '',
        promotion_category: 'RD',
        percentage: '',
        min_travel_time_in_sec: '',
        // usage limit
        daily_used: '',
        // max trips
        num_of_trips: '',
        // max discount
        max_amount: '',
        flat_discount_amount: '',
        assigned_fleets: '',
        description: '',
        eligible_user_types: [],
        eligible_point_categories: [],
      },
      customError: {
        title: '',
        code: '',
        percentage: '',
        min_travel_time_in_sec: '',
        num_of_trips: '',
        daily_used: '',
        flat_discount_amount: '',
        max_amount: '',
      },
      minStartDate: '',
      minEndDate: '',
      // fleet list is the raw data from server
      fleetList: [],
      // based on fleet currency getFleetOptions() is computed
      fleetCurrencyProxy: this.fleetCurrency,

      statusOptions: [
        {
          value: false,
          text: 'Inactive',
        },
        {
          value: true,
          text: 'Active',
        },
      ],
      targetRiderProfileOptions: [],
      targetGroupOptions: [
        { text: 'Silver', value: 'Silver' },
        { text: 'Gold', value: 'Gold' },
        { text: 'Diamond', value: 'Diamond' },
        { text: 'Platinum', value: 'Platinum' },
        { text: 'All', value: 'All' },
      ],
    }
  },
  computed: {
    getTitleText() {
      return this.isEditing
        ? this.$t('components.promoManagement.addEdit.headline.update')
        : this.$t('components.promoManagement.addEdit.headline.add')
    },
    getActionButtonText: function() {
      return this.isEditing
        ? this.$t('components.stepNavigation.update')
        : this.$t('components.stepNavigation.save')
    },
    getPromoDiscountText() {
      if (this.form.promotion_category === 'RD') {
        return this.form.percentage ? `${this.form.percentage}% OFF` : '--'
      } else if (this.form.promotion_category === 'FD') {
        return this.form.flat_discount_amount
          ? `${this.promoCurrencySymbol} ${this.form.flat_discount_amount} OFF`
          : '--'
      } else {
        return this.$t(
          'components.promoManagement.addEdit.steps.promo.card.freeRide'
        )
      }
    },

    getPromoTitle() {
      return (
        this.form?.title ||
        this.$t(
          'components.promoManagement.addEdit.steps.promo.card.promoTitle'
        )
      )
    },
    getPromoCode() {
      return this.form?.code || 'PROMOCODE'
    },
    getPromoDescription() {
      return (
        this.form?.description ||
        this.$t(
          'components.promoManagement.addEdit.steps.promo.card.promoDescription'
        )
      )
    },
    getPromoExpiredDate() {
      return this.getDate(this.form.end_date)
    },
    getPromoCategory() {
      return this.form?.promotion_category
    },
    getPromoCategoryName() {
      return getPromoCategoryName(this.form.promotion_category) || ''
    },
    getPromoValidityDuration() {
      if (!this.form.start_date && !this.form.end_date) return '--'

      const end = this.$dayjs(this.form.end_date)
      const diff = end.diff(this.form.start_date, 'day')
      return `${diff} ${diff > 1 ? 'days' : 'day'}`
    },
    getPromoValue() {
      const amount = this.form?.flat_discount_amount || 0
      const percentage = this.form?.percentage || 0

      return this.getPromoCategory === 'RD'
        ? `${parseFloat(percentage).toFixed(0)}%`
        : this.getPromoCategory === 'FD'
        ? this.currencySymbol + parseFloat(amount).toFixed(2)
        : ''
    },
    getPromoCategoryOptions() {
      return [
        { text: 'Rate Discount', value: 'RD' },
        { text: 'Flat Discount', value: 'FD' },
        { text: 'Free Ride', value: 'FR' },
      ]
    },
    getFleetOptions() {
      const fleetListFiltered = this.fleetList.filter((fleet) => {
        return this.fleetCurrencyProxy === fleet.country.code3
      })

      this.setPromoCurrencySymbol(fleetListFiltered)

      return fleetListFiltered.map((fleet) => ({
        text: `${fleet.name} (${fleet.country.code3})`,
        value: fleet.id,
      }))
    },
    // get unique currencies from fleet list
    getFleetCurrencyOptions() {
      const currencyList = new Set()

      this.fleetList.forEach((fleet) => {
        currencyList.add(fleet.country.code3)
      })

      return Array.from(currencyList)
    },
  },
  watch: {
    'formData': {
      immediate: true,
      deep: true,
      handler(data) {
        if (Object.keys(data).length > 1) {
          this.form = { ...data }
        } else {
          this.resetForm()
          this.$edgeStack.shouldNotConfirm(this.esId)
        }
      },
    },
    // notify form is dirty & user should confirm before exiting
    'form': {
      immediate: false,
      deep: true,
      handler(updatedFormData) {
        if (
          deepCompareObjects(this.formData, updatedFormData) ||
          deepCompareObjects(updatedFormData, this.getFormModel())
        ) {
          return
        } else {
          this.$edgeStack.shouldConfirm(this.esId)
        }
      },
    },
    'form.eligible_user_types'(newValue) {
      // eligible_user_types value empty array means it's applied for all categories
      if (newValue.length === 0) {
        this.form.eligible_user_types.push('All')
      }
      if (newValue.length > 1 && newValue.includes('All')) {
        const index = newValue.indexOf('All')
        if (index !== -1) {
          this.form.eligible_user_types.splice(index, 1)
        }
      }
    },
    'form.eligible_point_categories'(newValue) {
      // eligible_point_categories value empty array means it's applied for all categories
      if (newValue.length === 0) {
        this.form.eligible_point_categories.push('All')
      }
      if (newValue.length > 1 && newValue.includes('All')) {
        const index = newValue.indexOf('All')
        if (index !== -1) {
          this.form.eligible_point_categories.splice(index, 1)
        }
      }
    },
    'fleetCurrency'(updated) {
      this.fleetCurrencyProxy = updated
    },
    // reset selected fleets on changing fleet currency
    'fleetCurrencyProxy'(updated) {
      if (this.isEditing && updated === this.fleetCurrency) return

      if (updated) this.form.assigned_fleets = []
    },
  },
  async created() {
    this.fleetList = await this.$http
      .get('/dashboard/fleets/?dropdown')
      .then((res) => res.data.data)
      .catch((err) => console.log('fleetListErr', err))
    this.targetRiderProfileOptions = await this.$http
      .get(useEndpoints.pass.profileTypeChoices())
      .then((res) => [...res.data, { text: 'All', value: 'All' }])
      .catch((err) => console.log('profile-type-err', { err }))
    this.minDatecalculation()
  },
  mounted() {
    edgeStackEmitter.on(
      this.$edgeStack.getEventName('close', this.esId),
      () => {
        this.$edgeStack.shouldNotConfirm(this.esId)
      }
    )
    EventBus.$on('fresh-sense', ($event) => {
      if ($event === '') {
        this.customError = {
          title: '',
          code: '',
          percentage: '',
          min_travel_time_in_sec: '',
          num_of_trips: '',
          daily_used: '',
          flat_discount_amount: '',
          max_amount: '',
        }
        this.fleetCurrencyProxy = []
      }
    })
  },
  methods: {
    setPromoCurrencySymbol(data) {
      if (data.length) {
        this.promoCurrencySymbol = data[0].country.currency_symbol
      } else {
        this.promoCurrencySymbol = '--'
      }
    },
    minDatecalculation() {
      this.minStartDate = dayjs().format('YYYY-MM-DD')
      this.minEndDate = dayjs()
        .add(1, 'day')
        .format('YYYY-MM-DD')
    },
    getDate(date) {
      let result = ''
      var arr_month = new Array()
      arr_month[0] = 'Jan'
      arr_month[1] = 'Feb'
      arr_month[2] = 'Mar'
      arr_month[3] = 'Apr'
      arr_month[4] = 'May'
      arr_month[5] = 'Jun'
      arr_month[6] = 'Jul'
      arr_month[7] = 'Aug'
      arr_month[8] = 'Sep'
      arr_month[9] = 'Oct'
      arr_month[10] = 'Nov'
      arr_month[11] = 'Dec'

      var time_body = new Date(date)

      let year = time_body.getFullYear()
      let month_idx = time_body.getMonth()
      let day = time_body.getDate()
      var month2 = arr_month[month_idx]
      if (day && month2 && year) {
        result = day + ' ' + month2 + ' ' + year
        return result
      } else {
        return '--'
      }
    },
    onDirty() {
      this.$edgeStack.shouldConfirm(this.esId)
    },
    submit() {
      this.$refs.submitButton.click()
    },
    async submitForm() {
      const jsonstring_eligible_user_types = JSON.stringify(
        this.form.eligible_user_types.includes('All')
          ? []
          : this.form.eligible_user_types
      )

      const jsonstring_eligible_point_categories = JSON.stringify(
        this.form.eligible_point_categories.includes('All')
          ? []
          : this.form.eligible_point_categories
      )
      let formProxy = {
        ...this.form,
        eligible_user_types: jsonstring_eligible_user_types,
        eligible_point_categories: jsonstring_eligible_point_categories,
      }
      formProxy.start_date = formProxy.start_date + 'T00:00:00'
      formProxy.end_date = formProxy.end_date + 'T00:00:00'
      formProxy.description = formProxy.description || ''

      if (this.form.min_travel_time_in_sec) {
        formProxy.min_travel_time_in_sec =
          parseInt(this.form.min_travel_time_in_sec) * 60
      }

      let method = this.isEditing ? 'PATCH' : 'POST'
      let url = this.isEditing
        ? PromoConfig.api.update(this.primaryKey)
        : PromoConfig.api.create

      const formData = new FormData()
      // todo: make a form data converter function
      for (const key in formProxy) {
        if (Array.isArray(formProxy[key])) {
          formProxy[key].forEach((el) => {
            formData.append(key, el)
          })
          continue
        }
        formData.append(key, formProxy[key])
      }

      const message = this.isEditing
        ? 'Promotion updated successfully'
        : 'Promotion added successfully'

      this.reqBusy = true
      try {
        const res = await this.$http({ method, url, data: formData })

        // emit save event
        this.$emit('save', res.data)

        // Close ES
        this.$edgeStack.close(this.esId)
        // Reset the form
        this.$edgeStack.emitter.on(
          this.$edgeStack.getEventName('closed', this.esId),
          () => {
            this.resetForm()
            // this.$edgeStack.shouldNotConfirm(this.esId)
          }
        )

        this.$edgeStack.shouldNotConfirm(this.esId)

        this.$notify({
          group: 'generic',
          type: 'success',
          title: 'Success',
          text: message,
        })
      } catch (err) {
        const asCustomErrorArray = Object.entries(this.customError)
        const filteredKeyArray = asCustomErrorArray.filter(([key, value]) => {
          console.log(value)
          return Object.entries(err.response.data)[0][0].endsWith(key)
        })
        if (filteredKeyArray.length > 0) {
          this.customError[filteredKeyArray[0][0]] = Object.entries(
            err.response.data
          )[0][1][0]
        } else {
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: 'Failed',
              text: err.response.data,
            },
            10000
          )
        }
      } finally {
        this.reqBusy = false
        this.$edgeStack.shouldNotConfirm(this.esId, 'save_clicked')
      }
    },
    getFormModel() {
      return {
        is_active: true,
        title: '',
        code: '',
        start_date: '',
        end_data: '',
        promotion_category: 'RD',
        percentage: '',
        min_travel_time_in_sec: '',
        // usage limit
        daily_used: '',
        // max trips
        num_of_trips: '',
        // max discount
        max_amount: '',
        flat_discount_amount: '',
        assigned_fleets: '',
        description: '',
        eligible_user_types: [],
        eligible_point_categories: [],
      }
    },
    resetForm() {
      this.form = { ...this.getFormModel() }
    },
  },
}
</script>

<style lang="scss" scoped>
.header-title {
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}
.header-status {
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
  margin-right: 10px;
}
.toggle-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}
.promo-type-card {
  width: 100%;
  height: 200px;
  background: white;
  border-radius: 22px;
  border: 3px dashed #ee9200;
  margin-top: 20px;
  position: relative;
  .promo-right-icon {
    width: 200px;
    height: 55px;
    background: #ee9200;
    position: absolute;
    right: -3px;
    top: -3px;
    border-top-right-radius: 22px;
    border-bottom-left-radius: 60px;
    display: flex;
    span {
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      font-family: Roboto;
    }
  }
  .promo-card-body {
    h3 {
      font-family: Roboto;
      font-size: 37px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #0d0d0d;
    }
    span {
      font-size: 19px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ee9200;
      margin-top: 5px;
    }
  }
}
.multi-selecter {
  span {
    width: 100%;
  }
}
.multiselect__tags {
  border-color: #cbd5e0 !important;
}
</style>
