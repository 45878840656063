var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('EdgeStack',{staticClass:"relative",attrs:{"id":_vm.esId,"header-class-name":"mb-4 mt-8 px-4","confirmQuestion":_vm.$t('components.edgeStack.confirmQuestion')},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"flex justify-between"},[_c('div',{staticClass:"items-center panel-title header-title"},[_vm._v(" "+_vm._s(_vm.getTitleText)+" ")])])]},proxy:true},{key:"footer",fn:function(){return [_c('t-button',{staticClass:"ml-3",attrs:{"type":"submit"},on:{"click":function($event){return _vm.submit()}}},[_vm._v(_vm._s(_vm.getActionButtonText))])]},proxy:true}])},[_c('loading',{attrs:{"active":_vm.reqBusy,"is-full-page":false},on:{"update:active":function($event){_vm.reqBusy=$event}}}),_c('div',{staticClass:"px-6 pt-8"},[_c('PromoCard',{ref:"promoCard",attrs:{"promoDiscountText":_vm.getPromoDiscountText,"promoTitle":_vm.getPromoTitle,"promoCode":_vm.getPromoCode,"promoDescription":_vm.getPromoDescription,"promoExpiredDate":_vm.getPromoExpiredDate}}),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{attrs:{"enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitForm)}}},[_c('div',{staticClass:" mt-8"},[_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
                'components.promoManagement.addEdit.steps.promo.title.promoType'
              ),"label":_vm.$t(
                'components.promoManagement.addEdit.steps.promo.title.promoType'
              ),"options":_vm.getPromoCategoryOptions,"hide-search-box":"","rules":"required"},model:{value:(_vm.form.promotion_category),callback:function ($$v) {_vm.$set(_vm.form, "promotion_category", $$v)},expression:"form.promotion_category"}})],1),_c('div',{staticClass:"grid grid-cols-2 gap-4 mt-2"},[_c('text-input',{attrs:{"rules":"required","label":_vm.$t(
                'components.promoManagement.addEdit.steps.promo.title.promoName'
              ),"placeholder":"e.g. Bijoy","errorMessage":_vm.customError.title,"errorKey":"title"},on:{"clear-error":function ($event) {
                _vm.customError[$event] = ''
              }},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),_c('text-input',{attrs:{"value":_vm.form.code.toUpperCase(),"rules":"required","label":_vm.$t(
                'components.promoManagement.addEdit.steps.promo.title.promoCode'
              ),"placeholder":"e.g. BIJOY16","errorMessage":_vm.customError.code,"errorKey":"code"},on:{"input":function ($event) {
                _vm.form.code = $event.toUpperCase()
              },"clear-error":function ($event) {
                _vm.customError[$event] = ''
              }}})],1),_c('div',{staticClass:"grid grid-cols-2 gap-4 mt-2"},[_c('text-input',{attrs:{"rules":"required","label":_vm.$t(
                'components.promoManagement.addEdit.steps.promo.title.promoStartDate'
              ),"type":"date","min":_vm.minStartDate},model:{value:(_vm.form.start_date),callback:function ($$v) {_vm.$set(_vm.form, "start_date", $$v)},expression:"form.start_date"}}),_c('text-input',{attrs:{"rules":"required","label":_vm.$t(
                'components.promoManagement.addEdit.steps.promo.title.promoExpireDate'
              ),"type":"date","min":_vm.minEndDate},model:{value:(_vm.form.end_date),callback:function ($$v) {_vm.$set(_vm.form, "end_date", $$v)},expression:"form.end_date"}})],1),_c('div',{staticClass:"w-full mt-2"},[_c('text-input',{attrs:{"value":_vm.getPromoValidityDuration,"label":_vm.$t(
                'components.promoManagement.addEdit.steps.promo.title.usageValidity'
              ),"placeholder":"days","disabled":""}})],1),_c('div',{staticClass:"mt-2"},[_c('ValidationProvider',{attrs:{"name":_vm.$t(
                'components.promoManagement.addEdit.steps.promo.title.fleetCurrency'
              ),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('input-label',{attrs:{"text":_vm.$t(
                  'components.promoManagement.addEdit.steps.promo.title.fleetCurrency'
                ),"error":errors[0]}}),_c('TRichSelect',{attrs:{"options":_vm.getFleetCurrencyOptions,"variant":{ danger: !!errors[0] },"placeholder":_vm.$t(
                  'components.promoManagement.addEdit.steps.promo.placeholder.fleetCurrency'
                )},model:{value:(_vm.fleetCurrencyProxy),callback:function ($$v) {_vm.fleetCurrencyProxy=$$v},expression:"fleetCurrencyProxy"}}),(errors[0])?_c('input-error-item',{attrs:{"message":errors[0]}}):_vm._e()]}}],null,true)}),_c('div',{staticClass:"mt-1 text-xs text-gray-600",domProps:{"innerHTML":_vm._s(
              _vm.$t(
                'components.promoManagement.addEdit.steps.promo.infoDescription.fleetCurrency.fleetsInfo',
                {
                  count: _vm.fleetList.length || 0,
                  currencyCount: _vm.getFleetCurrencyOptions.length || 0,
                  currencyText: _vm.$t(
                    _vm.getFleetCurrencyOptions.length > 1
                      ? 'components.promoManagement.addEdit.steps.promo.infoDescription.fleetCurrency.currencies'
                      : 'components.promoManagement.addEdit.steps.promo.infoDescription.fleetCurrency.currency'
                  ),
                }
              )
            )}})],1),_c('div',{staticClass:"mt-4"},[_c('ValidationProvider',{attrs:{"name":_vm.$t(
                'components.promoManagement.addEdit.steps.promo.title.assignedFleet'
              ),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('input-label',{attrs:{"error":errors[0],"text":_vm.$t(
                  'components.promoManagement.addEdit.steps.promo.title.assignedFleet'
                )}}),_c('TRichSelect',{attrs:{"options":_vm.getFleetOptions,"close-on-select":false,"variant":{ danger: !!errors[0] },"placeholder":_vm.fleetCurrencyProxy
                  ? _vm.$t(
                      'components.promoManagement.addEdit.steps.promo.placeholder.assignedFleet.selectFleets'
                    )
                  : _vm.$t(
                      'components.promoManagement.addEdit.steps.promo.placeholder.assignedFleet.selectFleetCurrencyFirst'
                    ),"multiple":""},model:{value:(_vm.form.assigned_fleets),callback:function ($$v) {_vm.$set(_vm.form, "assigned_fleets", $$v)},expression:"form.assigned_fleets"}}),_c('input-error-item',{attrs:{"message":errors[0]}})]}}],null,true)})],1),_c('div',{staticClass:"mt-4"},[_c('ValidationProvider',{attrs:{"name":_vm.$t(
                'components.promoManagement.addEdit.steps.promo.title.targetRiderProfile'
              ),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('input-label',{attrs:{"error":errors[0],"text":_vm.$t(
                  'components.promoManagement.addEdit.steps.promo.title.targetRiderProfile'
                )}}),_c('TRichSelect',{attrs:{"options":_vm.targetRiderProfileOptions,"close-on-select":false,"variant":{ danger: !!errors[0] },"placeholder":_vm.$t(
                  'components.promoManagement.addEdit.steps.promo.placeholder.targetRiderProfile'
                ),"multiple":"","hideSearchBox":""},model:{value:(_vm.form.eligible_user_types),callback:function ($$v) {_vm.$set(_vm.form, "eligible_user_types", $$v)},expression:"form.eligible_user_types"}}),_c('input-error-item',{attrs:{"message":errors[0]}})]}}],null,true)})],1),_c('div',{staticClass:"mt-4"},[_c('ValidationProvider',{attrs:{"name":_vm.$t(
                'components.promoManagement.addEdit.steps.promo.title.targetRiderGroup'
              ),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('input-label',{attrs:{"error":errors[0],"text":_vm.$t(
                  'components.promoManagement.addEdit.steps.promo.title.targetRiderGroup'
                )}}),_c('TRichSelect',{attrs:{"options":_vm.targetGroupOptions,"close-on-select":false,"variant":{ danger: !!errors[0] },"placeholder":_vm.$t(
                  'components.promoManagement.addEdit.steps.promo.placeholder.targetRiderGroup'
                ),"multiple":"","hideSearchBox":""},model:{value:(_vm.form.eligible_point_categories),callback:function ($$v) {_vm.$set(_vm.form, "eligible_point_categories", $$v)},expression:"form.eligible_point_categories"}}),_c('input-error-item',{attrs:{"message":errors[0]}})]}}],null,true)})],1),(_vm.getPromoCategory != 'FR')?_c('div',{staticClass:"grid grid-cols-2 gap-4 mt-2"},[(_vm.getPromoCategory == 'RD')?[_c('AppInput',{attrs:{"type":"number","rules":"required","name":_vm.$t(
                  'components.promoManagement.addEdit.steps.promo.title.discountPercentage'
                ),"label":_vm.$t(
                  'components.promoManagement.addEdit.steps.promo.title.discountPercentage'
                ),"placeholder":"e.g. 60","step":"0.01","haveUnitText":true,"unitText":"%"},model:{value:(_vm.form.percentage),callback:function ($$v) {_vm.$set(_vm.form, "percentage", $$v)},expression:"form.percentage"}})]:(_vm.getPromoCategory == 'FD')?[_c('AppInput',{attrs:{"type":"number","rules":"required","name":_vm.$t(
                  'components.promoManagement.addEdit.steps.promo.title.flatDiscountAmount'
                ),"label":_vm.$t(
                  'components.promoManagement.addEdit.steps.promo.title.flatDiscountAmount'
                ),"placeholder":"e.g. $20","haveSuggestion":true,"suggestionText":_vm.$t(
                  'components.promoManagement.addEdit.steps.promo.infoDescription.flatDiscountAmount'
                ),"step":"0.01"},model:{value:(_vm.form.flat_discount_amount),callback:function ($$v) {_vm.$set(_vm.form, "flat_discount_amount", $$v)},expression:"form.flat_discount_amount"}})]:_vm._e(),_c('AppInput',{attrs:{"type":"number","rules":"required","name":_vm.$t(
                'components.promoManagement.addEdit.steps.promo.title.minimumTripTime'
              ),"label":_vm.$t(
                'components.promoManagement.addEdit.steps.promo.title.minimumTripTime'
              ),"placeholder":"e.g. 20","haveSuggestion":true,"suggestionText":_vm.$t(
                'components.promoManagement.addEdit.steps.promo.infoDescription.minimumTripTime'
              ),"step":"1","haveUnitText":true,"unitText":"Min"},model:{value:(_vm.form.min_travel_time_in_sec),callback:function ($$v) {_vm.$set(_vm.form, "min_travel_time_in_sec", $$v)},expression:"form.min_travel_time_in_sec"}})],2):_vm._e(),_c('div',{staticClass:"grid grid-cols-2 gap-4 mt-2"},[_c('AppInput',{attrs:{"type":"number","rules":"required","name":_vm.$t(
                'components.promoManagement.addEdit.steps.promo.title.maxNumberOfTrips'
              ),"label":_vm.$t(
                'components.promoManagement.addEdit.steps.promo.title.maxNumberOfTrips'
              ),"placeholder":"e.g. 10","haveSuggestion":true,"suggestionText":_vm.$t(
                'components.promoManagement.addEdit.steps.promo.infoDescription.maxNumberOfTrips'
              ),"step":"1","haveUnitText":true,"unitText":"Trips"},model:{value:(_vm.form.num_of_trips),callback:function ($$v) {_vm.$set(_vm.form, "num_of_trips", $$v)},expression:"form.num_of_trips"}}),_c('AppInput',{attrs:{"type":"number","rules":"required","name":_vm.$t(
                'components.promoManagement.addEdit.steps.promo.title.dailyUsed'
              ),"label":_vm.$t(
                'components.promoManagement.addEdit.steps.promo.title.dailyUsed'
              ),"placeholder":"e.g. 10","haveSuggestion":true,"suggestionText":_vm.$t(
                'components.promoManagement.addEdit.steps.promo.infoDescription.dailyUsed'
              ),"step":"1"},model:{value:(_vm.form.daily_used),callback:function ($$v) {_vm.$set(_vm.form, "daily_used", $$v)},expression:"form.daily_used"}})],1),_c('div',{staticClass:"grid grid-cols-2 gap-4 mt-2"},[_c('AppInput',{attrs:{"type":"number","rules":"required","name":_vm.$t(
                'components.promoManagement.addEdit.steps.promo.title.userLimit'
              ),"label":_vm.$t(
                'components.promoManagement.addEdit.steps.promo.title.userLimit'
              ),"placeholder":"e.g. 10","haveSuggestion":false,"step":"1","haveUnitText":true,"unitText":"Riders"},model:{value:(_vm.form.user_limit),callback:function ($$v) {_vm.$set(_vm.form, "user_limit", $$v)},expression:"form.user_limit"}}),_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
                'components.promoManagement.addEdit.steps.promo.title.visibility'
              ),"label":_vm.$t(
                'components.promoManagement.addEdit.steps.promo.title.visibility'
              ),"options":[
              { text: 'Public', value: 0 },
              { text: 'Private', value: 1 } ],"hide-search-box":""},model:{value:(_vm.form.visibility),callback:function ($$v) {_vm.$set(_vm.form, "visibility", $$v)},expression:"form.visibility"}})],1),(_vm.getPromoCategory == 'RD')?_c('div',{staticClass:"w-full"},[_c('AppInput',{attrs:{"type":"number","rules":"required","name":_vm.$t(
                'components.promoManagement.addEdit.steps.promo.title.maxDiscountedTripCost'
              ),"label":_vm.$t(
                'components.promoManagement.addEdit.steps.promo.title.maxDiscountedTripCost'
              ),"placeholder":"e.g. $5","haveSuggestion":true,"suggestionText":_vm.$t(
                'components.promoManagement.addEdit.steps.promo.infoDescription.maxDiscountedTripCost'
              ),"step":"0.01"},model:{value:(_vm.form.max_amount),callback:function ($$v) {_vm.$set(_vm.form, "max_amount", $$v)},expression:"form.max_amount"}})],1):_vm._e(),_c('div',{staticClass:"mt-2"},[_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
                'components.promoManagement.addEdit.steps.promo.title.status'
              ),"label":_vm.$t(
                'components.promoManagement.addEdit.steps.promo.title.status'
              ),"options":_vm.statusOptions,"hide-search-box":"","rules":"required"},model:{value:(_vm.form.is_active),callback:function ($$v) {_vm.$set(_vm.form, "is_active", $$v)},expression:"form.is_active"}})],1),_c('div',{staticClass:"mt-5"},[_c('ValidationProvider',{attrs:{"name":_vm.$t(
                'components.promoManagement.addEdit.steps.promo.title.promoDescription'
              )},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('input-label',{attrs:{"error":errors[0],"text":_vm.$t(
                  'components.promoManagement.addEdit.steps.promo.title.promoDescription'
                )}}),_c('t-textarea',{attrs:{"rules":""},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('input-error-item',{attrs:{"message":errors[0]}})]}}],null,true)})],1),_c('button',{ref:"submitButton",staticClass:"hidden",attrs:{"type":"submit"}},[_vm._v("Save")])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }