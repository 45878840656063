<template>
  <base-layout>
    <PromotionAddEdit
      :es-id="ae.esId"
      :primary-key="ae.primaryKey"
      :form-data="ae.formData"
      :is-editing="ae.isEditing"
      :fleet-currency="ae.fleetCurrency"
      :currency-symbol="ae.currencySymbol"
      @save="
        () => {
          $edgeStack.shouldNotConfirm(ae.esId)
          $store.dispatch('fsTable/fetchData')
        }
      "
      @close="$edgeStack.shouldNotConfirm(ae.esId)"
    />

    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10 md:grid-cols-2">
          <TitlePlus
            :title="$t('components.promoManagement.headline')"
            @plus="onOpenES"
            :hidePlus="$acl.canNotCreate('promotions')"
          />

          <div class="flex md:justify-end">
            <date-range-picker
              @apply-date="onApplyFilterDateRange"
              @cancel-date="onApplyFilterDateRange"
            />
          </div>
        </div>

        <div class="summary-card-container">
          <SummaryCard
            :title="$t('components.promoManagement.summary.currentPromo')"
            :value="indexMetaData.summary.current"
            variant="gray"
          />
          <SummaryCard
            :title="$t('components.promoManagement.summary.currentPromoUser')"
            :value="indexMetaData.summary.current_promo_users"
            variant="gray"
          />
        </div>
      </div>
      <div
        v-if="$acl.canNotView('promotions')"
        class="py-5 font-bold text-center text-gray-600"
      >
        {{ $t('components.acl.doNotHavePermission') }}
      </div>

      <FSTable
        v-else
        :fst-id="`promotionIndex`"
        :headers="getTableHeaders"
        :endpoint="indexDataEndpoint"
        @meta="(e) => (indexMetaData = e)"
      >
        <template #topLeft="{ slotWidth }">
          <keep-alive>
            <FSTableFilter
              :fst-id="`promotionIndex`"
              :slot-width="slotWidth"
              :options="getFilteredItems"
            />
          </keep-alive>
        </template>

        <template #default="{ data }">
          <template v-for="(item, itemIndex) in data">
            <template v-if="getTableMode === 'full'">
              <FSTableRow :key="itemIndex" textFallbackAlways>
                <FSTableRowItem
                  :text="item.id"
                  :truncate="-5"
                  :to="{ name: 'PromotionDetails', params: { id: item.id } }"
                />

                <FSTableRowItem :text="item.title" />

                <FSTableRowItem>
                  <div
                    class="flex items-center gap-2 flex-wrap"
                    v-if="
                      item.assigned_fleets && item.assigned_fleets.length > 0
                    "
                  >
                    <RouterLink
                      class="text-blue-600 "
                      v-for="(fleet, key) in item.assigned_fleets"
                      :key="key"
                      :to="{
                        name: 'ViewFleetProfile',
                        params: { id: fleet.id },
                      }"
                      target="_blank"
                      >{{ fleet.name }}</RouterLink
                    >
                  </div>
                </FSTableRowItem>

                <FSTableRowItem
                  :text="getPromoTypeChoice(item.promotion_category)"
                />

                <FSTableRowItem
                  :text="item.code"
                  :class-name="`text-oOrange`"
                />

                <FSTableRowItem>
                  {{ item.end_date | friendlyDateTime }}
                  <p>
                    (<TimeAgo
                      :title="item.end_date"
                      :datetime="item.end_date"
                      :auto-update="60"
                    />)
                  </p>
                </FSTableRowItem>

                <FSTableRowItem>
                  {{ getPromoCurrencySymbol(item) }}
                  {{ parseFloat(item.max_amount).toFixed(2) }}
                </FSTableRowItem>

                <FSTableRowItem
                  :text="item.description"
                  :truncate="30"
                  :truncate-append="`...`"
                />

                <FSTableRowItem>
                  <x-status
                    :text="item.status"
                    :variant="getItemVariant(item.status)"
                  />
                </FSTableRowItem>

                <FSTableRowItem>
                  <div class="flex items-center gap-1">
                    <OtoEditIcon
                      @click="onOpenES(item.id)"
                      v-if="item.status !== 'Expired'"
                    />
                    <MoreActions
                      :data="item"
                      @refresh="$store.dispatch('fsTable/fetchData')"
                    />
                  </div>
                </FSTableRowItem>
              </FSTableRow>
            </template>
            <template v-if="getTableMode === 'responsive'">
              <FSTableRow :key="`fs-table-row-${itemIndex}`" textFallbackAlways>
                <FSTableRowItem>
                  <div
                    class="col-span-1 focus:text-gray-400"
                    @click="toggle(itemIndex)"
                  >
                    <i
                      class="fas fa-minus-circle"
                      style="color:#d90a20;"
                      v-if="opened.includes(itemIndex)"
                    ></i>
                    <i class="fas fa-plus-circle" v-else></i>
                  </div>
                </FSTableRowItem>
                <FSTableRowItem
                  :text="item.title"
                  :to="{ name: 'PromotionDetails', params: { id: item.id } }"
                />
                <FSTableRowItem
                  :text="getPromoTypeChoice(item.promotion_category)"
                />
                <FSTableRowItem>
                  <div
                    class="flex items-center gap-2 flex-wrap"
                    v-if="
                      item.assigned_fleets && item.assigned_fleets.length > 0
                    "
                  >
                    <RouterLink
                      class="text-blue-600 "
                      v-for="(fleet, key) in item.assigned_fleets"
                      :key="key"
                      :to="{
                        name: 'ViewFleetProfile',
                        params: { id: fleet.id },
                      }"
                      target="_blank"
                      >{{ fleet.name }}</RouterLink
                    >
                  </div>
                </FSTableRowItem>
              </FSTableRow>
              <FSTableRow v-if="opened.includes(itemIndex)" :key="itemIndex">
                <td colspan="10">
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{ $t('components.promoManagement.table.columns.id') }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{ item.id | onlyLastFive }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t('components.promoManagement.table.columns.promoCode')
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      <p class="text-oOrange">
                        {{ item.code || '--' }}
                      </p>
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t('components.promoManagement.table.columns.expiredAt')
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{ item.end_date | friendlyDateTime }}
                      <p>
                        (<TimeAgo
                          :title="item.end_date"
                          :datetime="item.end_date"
                          :auto-update="60"
                        />)
                      </p>
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t('components.promoManagement.table.columns.maxAmount')
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{ getPromoCurrencySymbol(item) }}
                      {{ parseFloat(item.max_amount).toFixed(2) }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.promoManagement.table.columns.description'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{ $truncate(item.description, 30, null, '...') }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t('components.promoManagement.table.columns.status')
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      <x-status
                        :text="item.status"
                        :variant="getItemVariant(item.status)"
                      />
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t('components.promoManagement.table.columns.actions')
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      <div class="flex items-center gap-1">
                        <OtoEditIcon
                          @click="onOpenES(item.id)"
                          v-if="item.status !== 'Expired'"
                        />
                        <MoreActions
                          :data="item"
                          @refresh="$store.dispatch('fsTable/fetchData')"
                        />
                      </div>
                    </div>
                  </div>
                </td>
              </FSTableRow>
            </template>
          </template>
        </template>
      </FSTable>
    </content-section>
  </base-layout>
</template>

<script>
import { PromoConfig } from '@/config/PromoConfig'

import BaseLayout from '@/views/shared/BaseLayout.vue'
import XStatus from '@/components/badge/XStatus'
import SummaryCard from '@/components/cards/SummaryCard'
import ContentSection from '@/components/layout/ContentSection'
import DateRangePicker from '@/components/picker/date-range/DateRangePicker'
import MoreActions from '@/composites/promotion/MoreActions.vue'
import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  FSTableFilter,
  onApplyFilterDateRange,
} from '@/components/fs-table'

import PromotionAddEdit from './PromotionAddEdit'

import TitlePlus from '@/components/ui/TitlePlus.vue'
import OtoEditIcon from '@/components/ui/OtoEditIcon.vue'

export default {
  name: 'PromotionIndex',
  components: {
    BaseLayout,
    ContentSection,
    PromotionAddEdit,
    SummaryCard,
    XStatus,
    DateRangePicker,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    OtoEditIcon,
    TitlePlus,
    FSTableFilter,
    MoreActions,
  },
  props: {
    filterItems: {
      type: Array,
      default: () => [],
    },
  },
  async created() {
    await this.generateFilterOptions()
  },
  computed: {
    getTableHeaders() {
      if (this.windowWidth <= 930) {
        return this.resTableHeaders
      } else {
        return this.tableHeaders
      }
    },
    getTableMode() {
      if (this.windowWidth <= 930) {
        return 'responsive'
      } else {
        return 'full'
      }
    },
    currencySymbol() {
      return this?.$org?.default_currency?.symbol || '$'
    },
    getFilteredItems() {
      if (this.filterItems.length) {
        if (this.filterItems.length === this.filterOptions.length) {
          let propFilterItems = this.filterItems
          propFilterItems.push({}) //-for updating filteritems into rendering moment
          return propFilterItems
        } else {
          return this.filterItems
        }
      } else {
        return this.filterOptions
      }
    },
  },
  data() {
    return {
      indexMetaData: {
        summary: {
          current: 0,
          current_promo_users: 0,
        },
      },
      indexDataEndpoint: PromoConfig.api.index,
      windowWidth: window.innerWidth,
      opened: [],
      tableHeaders: [
        {
          text: `${this.$t('components.promoManagement.table.columns.id')}`,
          width: '7%',
          sort: 'id',
        },
        {
          text: `${this.$t(
            'components.promoManagement.table.columns.promoName'
          )}`,
          width: '10%',
          sort: 'title',
        },
        {
          text: `${this.$t('components.promoManagement.table.columns.fleet')}`,
          width: '13%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.promoManagement.table.columns.promoType'
          )}`,
          width: '10%',
          sort: 'promotion_category',
        },
        {
          text: `${this.$t(
            'components.promoManagement.table.columns.promoCode'
          )}`,
          width: '10%',
          sort: 'code',
        },
        {
          text: `${this.$t(
            'components.promoManagement.table.columns.expiredAt'
          )}`,
          width: '15%',
          sort: 'end_date',
        },
        {
          text: `${this.$t(
            'components.promoManagement.table.columns.maxAmount'
          )}`,
          width: '10%',
          sort: 'max_amount',
        },
        {
          text: `${this.$t(
            'components.promoManagement.table.columns.description'
          )}`,
          width: '17%',
          sort: 'description',
        },
        {
          text: `${this.$t('components.promoManagement.table.columns.status')}`,
          width: '10%',
          sort: 'is_active',
        },
        {
          text: `${this.$t(
            'components.promoManagement.table.columns.actions'
          )}`,
          width: '10%',
          sort: null,
        },
      ],
      resTableHeaders: [
        { text: '', width: '10%', sort: null },
        {
          text: `${this.$t(
            'components.promoManagement.table.columns.promoName'
          )}`,
          width: '20%',
          sort: 'title',
        },
        {
          text: `${this.$t(
            'components.promoManagement.table.columns.promoType'
          )}`,
          width: '20%',
          sort: 'promotion_category',
        },
        {
          text: `${this.$t('components.promoManagement.table.columns.fleet')}`,
          width: '20%',
          sort: null,
        },
      ],
      filterOptions: [],

      // add-edit
      ae: {
        esId: 'promotionAddEdit',
        primaryKey: 0,
        isEditing: false,
        formData: {},
        fleetCurrency: '',
        currencySymbol: '$',
      },
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },

  methods: {
    onApplyFilterDateRange,
    onResize() {
      this.windowWidth = window.innerWidth
    },
    toggle(id) {
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
    async generateFilterOptions() {
      let filters = []

      if (this.$acl.canView('fleets')) {
        let filterableFleets = []
        await this.$http.get('/dashboard/fleets/?dropdown').then(({ data }) => {
          if (data.data.length) {
            data.data.forEach((fleet) => {
              filterableFleets.push({ text: fleet.name, value: fleet.id })
            })
          }
        })
        filters.unshift({
          key: 'fleet',
          type: 'checkbox',
          input: filterableFleets,
          title: 'Fleet',
        })
        this.filterOptions = filters
      } else {
        this.filterOptions = filters
      }
    },
    async onOpenES(primaryKey = null) {
      if (this.$acl.canNotUpdate('promotions')) {
        this.$notify(
          this.$acl.notAbleToToast({
            action: 'update',
            permission: 'promotions',
          })
        )
        return
      }
      this.ae.currencySymbol = this.currencySymbol

      if (primaryKey) {
        this.$notify(
          {
            group: 'generic',
            type: 'default',
            title: `Processing..`,
            text: 'The action is being executed...',
          },
          600
        )

        // fetch data
        await this.$http
          .get(PromoConfig.api.detail(primaryKey))
          .then((res) => {
            this.ae.primaryKey = res.data.id
            this.ae.isEditing = true

            // set fleet currency
            this.ae.fleetCurrency =
              res.data.assigned_fleets[0]?.country?.code3 || ''

            let data = res.data
            // map asigned fleets id
            data.assigned_fleets = res.data.assigned_fleets.map((x) => x.id)
            console.log({
              org: res.data.assigned_fleets,
              map: data.assigned_fleets,
            })
            // map min_travel_time_in_sec into min
            data.min_travel_time_in_sec = res.data.min_travel_time_in_sec / 60

            // format date to match html date input
            data.start_date = this.$dayjs(res.data.start_date).format(
              'YYYY-MM-DD'
            )
            data.end_date = this.$dayjs(res.data.end_date).format('YYYY-MM-DD')
            // format percentage
            data.percentage = parseInt(res.data.percentage).toFixed()
            this.ae.formData = { ...data }

            // console.log({ da: res.data })
            // console.log({ fd: this.ae.formData })

            this.$edgeStack.open(this.ae.esId)
          })
          .catch((err) => {
            console.warn({ err })
            this.$notify(
              {
                group: 'generic',
                type: 'error',
                title: `Action failed to execute!`,
                text: 'Failed to retrieve the user. Please try again.',
              },
              5000
            )
          })
      } else {
        this.ae.isEditing = false
        this.ae.fleetCurrency = ''
        this.ae.primaryKey = 0
        this.ae.formData = {}
        this.$edgeStack.open(this.ae.esId)
      }
    },
    getItemVariant(data) {
      if (data === 'Active') return 'green'
      if (data === 'Inactive') return 'gray'
      return 'red'
    },

    getPromoTypeChoice(choice) {
      const promoTypeChoice = {
        FR: 'Free Ride',
        FD: 'Flat Discount',
        RD: 'Rate Discount',
      }
      return choice in promoTypeChoice ? promoTypeChoice[choice] : '--'
    },
    getPromoCurrencySymbol(promoItem) {
      const fleets = promoItem.assigned_fleets

      if (fleets.length) {
        return fleets[0].country?.currency_symbol || this.currencySymbol
      }

      return this.currencySymbol
    },
  },
}
</script>

<style lang="scss" scoped>
.left-text {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a4048;
}
.right-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
}
</style>
